import React from 'react';
import styled from 'styled-components';
import Logo from '../../../assets/images/dex/logo.svg';
import TwitterIcon from '../../../assets/images/socials/twitter.svg';
import TeleIcon from '../../../assets/images/socials/tele.svg';
import BookIcon from '../../../assets/images/socials/book.svg';
import { Text, Box } from 'rebass';
import Nav from 'components/Layout/Nav';

const LARGE_SCREEN_FOOTER_HEIGHT = 140;

const FooterSection = styled.div`
  margin-top: auto;
  width: 100%;
`;

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.black};
  height: ${LARGE_SCREEN_FOOTER_HEIGHT}px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 0.2rem;
  align-items: center;

  width: 100%;
  padding: 20px 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: auto;
    justify-content: center;
    row-gap: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};
`;

const FlexBox = styled.div`
  display: flex;
`;

const LeftSection = styled(FlexBox)`
  flex-direction: column;
  row-gap: 0.2rem;
  flex: 1;
  align-items: flex-start;

  ${({ theme }) => theme.mediaWidth.upToSmall`
   align-items: center;
  `};
`;

const CenterSection = styled(FlexBox)``;

const RightSection = styled(FlexBox)`
  justify-content: flex-end;
  flex: 1;
  gap: 24px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   justify-content: center;
  `};
`;

const FooterLogo = styled(FlexBox)`
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
`;

const Icon = styled.div`
  transition: transform 0.3s ease;
  margin-right: 5px;
  width: clamp(150px, 10.5vw, 271px);

  :hover {
    transform: scale(1.1);
  }

  img {
    width: 100%;
  }
`;

// const LogoText = styled.h2`
//   background: ${({ theme }) => `linear-gradient(180deg, ${theme.logoText} 0%, #000000 100%)`};
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   text-align: center;
//   font-size: clamp(26px, 3vw, 30px);

//   display: block;
//   margin: 0;
// `;

const ReaderFont = styled(Text).attrs({ className: 'readex-pro', fontSize: [14, 18], fontWeight: 400 })``;

const RightText = styled(ReaderFont)`
  white-space: nowrap;
`;

const CenterText = styled(ReaderFont)``;

const SocialLink = styled.a``;

const DisplayMedium = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
    margin: 1rem 0;
  `}
`;

const Footer = () => {
  return (
    <FooterSection>
      <DisplayMedium>
        <Nav />
      </DisplayMedium>
      <FooterWrapper>
        <LeftSection>
          <FooterLogo>
            <Icon>
              <img src={Logo} alt="logo" />
            </Icon>
            {/* <LogoText className="coiny-regular">CulturaFi</LogoText> */}
          </FooterLogo>
          <RightText>The Next-Gen DEX on Sophon Blockchain</RightText>
        </LeftSection>
        <CenterSection>
          <CenterText>© 2024 CulturaFi. All rights reserved.</CenterText>
        </CenterSection>
        <RightSection>
          <SocialLink href="https://x.com/Cultura_Fi" target="_blank">
            <Box width={[25, 30]}>
              <img src={TwitterIcon} alt="social-logo" style={{ width: '100%' }} />
            </Box>
          </SocialLink>
          <SocialLink href="https://t.me/CulturaFi_official" target="_blank">
            <Box width={[31, 36]}>
              <img src={TeleIcon} alt="social-logo" style={{ width: '100%' }} />
            </Box>
          </SocialLink>
          <SocialLink href="https://docs.culturafi.xyz" target="_blank">
            <Box width={[30, 35]}>
              <img src={BookIcon} alt="social-logo" style={{ width: '100%' }} />
            </Box>
          </SocialLink>
        </RightSection>
      </FooterWrapper>
    </FooterSection>
  );
};

export default Footer;
