import { ChainId } from '@uniswap/sdk';

export type ExtendedChainMap = {
  [chainId in ChainId]: string;
};

export const RPC_URLS: ExtendedChainMap = {
  [ChainId.MAINNET]: 'https://rpc.ankr.com/eth',
  [ChainId.ROPSTEN]: 'https://rpc.ankr.com/eth_ropsten',
  [ChainId.RINKEBY]: 'https://rpc.ankr.com/eth_rinkeby',
  [ChainId.GÖRLI]: 'https://rpc.ankr.com/eth_goerli',
  [ChainId.KOVAN]: 'http://kovan.poa.network:8545',
  [ChainId.SOPHON_TESTNET]: 'https://rpc.testnet.sophon.xyz',
};
