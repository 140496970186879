import { useCallback } from 'react';
import { getLeaderBoardContract } from '../utils';
import { BigNumber, utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';

export const useLeaderBoardCallback = () => {
  return useCallback(
    async ({
      type,

      account,
      inputAmount,
      outputAmount,
    }: {
      type: string;

      account: string;
      inputAmount: string;
      outputAmount: string;
    }) => {
      const contract: Contract | null = getLeaderBoardContract();
      let amount: BigNumber = utils.parseEther('0');

      let isBuyOrder = false;
      if (!contract) {
        return [];
      }

      if (type === 'swapExactETHForTokens' || type === 'swapETHForExactTokens') {
        isBuyOrder = true;
        amount = utils.parseEther(inputAmount);
      } else if (type === 'swapExactTokensForETH' || type === 'swapTokensForExactETH') {
        isBuyOrder = false;
        amount = utils.parseEther(outputAmount);
      }

      return contract['updateLeaderBoard'](account, isBuyOrder, amount)
        .then(async (response: any) => {
          return response.hash;
        })
        .catch((e: any) => {
          console.log(e);
        });
    },
    []
  );
};
