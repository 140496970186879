import React from 'react';
import Row from '../../Row';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';

const activeClassName = 'ACTIVE';

const HeaderLinks = styled(Row)`
  width: fit-content;
  margin: 0 auto;
  padding: 0.3rem;
  justify-content: center;
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;
  background-color: ${({ theme }) => theme.bg1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    background-color: transparent;
    box-shadow: none;
  `}/* ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 0;
    margin-right: auto;
    position: fixed;
    bottom: 0;
    padding: .5rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    border-top: 1px solid ${({ theme }) => theme.bg3};
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: fixed;
    bottom: 0;
    padding: .5rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    border-top: 1px solid ${({ theme }) => theme.bg3};
  `}; */
`;

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 0.9rem;
  width: fit-content;
  padding: 0.3rem 0.6rem;
  font-weight: 500;
  transition: 0.3s;

  &:not(:last-child) {
    margin-right: 0.16rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg3};

    ${({ theme }) => theme.mediaWidth.upToMedium`
        color: ${({ theme }) => theme.inputText};
        background-color: ${({ theme }) => theme.black};
        border-radius: 20px;
        min-height: 30px;
    `}
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg3};
    border-radius: 20px;
    padding: 0.3rem 1.5rem;
  
    &:not(:last-child) {
        margin-right: 2%;
    }
`};
`;

const Nav = () => {
  const { t } = useTranslation();

  return (
    <HeaderLinks>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
        {t('swap')}
      </StyledNavLink>
      <StyledNavLink
        id={`pool-nav-link`}
        to={'/pool'}
        isActive={(match, { pathname }) =>
          Boolean(match) ||
          pathname.startsWith('/add') ||
          pathname.startsWith('/remove') ||
          pathname.startsWith('/create') ||
          pathname.startsWith('/find')
        }
      >
        {t('pool')}
      </StyledNavLink>
    </HeaderLinks>
  );
};

export default Nav;
