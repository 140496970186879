import React from 'react';
import styled from 'styled-components';

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
  padding: 0.25rem 0.5rem;
  /* border-radius: 14px; */
  background: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.primary1 : theme.text4) : 'none')};
  color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text3)};
  font-size: 1rem;
  font-weight: 400;

  /* padding: 0.35rem 0.6rem; */
  /* border-radius: 12px; */
  background: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.black : theme.text4) : 'none')};
  color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text2)};
  font-size: 1rem;
  font-weight: ${({ isOnSwitch }) => (isOnSwitch ? '500' : '400')};

  border-radius: 50%;
  width: 12px;
  aspect-ratio: 1/1;
  height: 100%;

  :hover {
    user-select: ${({ isOnSwitch }) => (isOnSwitch ? 'none' : 'initial')};
    background: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.black : theme.text3) : 'none')};
    color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text3)};
  }
`;

const StyledToggle = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  border-radius: 12px;
  border: none;
  background: ${({ theme, isActive }) => (isActive ? theme.primary1 : theme.bg3)};
  border: 1px solid ${({ theme, isActive }) => (!isActive ? theme.primary1 : 'tranparent')};
  display: flex;
  justify-content: space-between;
  width: 52px;
  cursor: pointer;
  outline: none;
  padding: 2px;
`;

export interface ToggleProps {
  id?: string;
  isActive: boolean;
  toggle: () => void;
}

export default function Toggle({ id, isActive, toggle }: ToggleProps) {
  return (
    <StyledToggle id={id} isActive={isActive} onClick={toggle}>
      <ToggleElement isActive={!isActive} isOnSwitch={false}>
        {/* Off */}
      </ToggleElement>
      <ToggleElement isActive={isActive} isOnSwitch={true}>
        {/* On */}
      </ToggleElement>
    </StyledToggle>
  );
}
