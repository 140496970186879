import React from 'react';
import { ButtonPrimary } from 'components/Button';
import styled from 'styled-components';
import { useWalletModalToggle } from 'state/application/hooks';

const StyledConnectButton = styled(ButtonPrimary)`
  font-weight: 700;
  font-size: clamp(14px, 3.5vw, 18px);
`;

const ConnectWalletButton = () => {
  const toggleWalletModal = useWalletModalToggle();

  return <StyledConnectButton onClick={toggleWalletModal}>Connect Wallet</StyledConnectButton>;
};

export default ConnectWalletButton;
