import { ChainId } from '@uniswap/sdk';
import React from 'react';
// {
//   useEffect,
//   useRef,
//   useState
// }
import { Text } from 'rebass';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
// import Logo from '../../assets/svg/logo.svg';
import Logo from '../../assets/images/dex/logo.svg';
import { useActiveWeb3React } from '../../hooks';
import { useDarkModeManager } from '../../state/user/hooks';
import { useETHBalances } from '../../state/wallet/hooks';
import Nav from 'components/Layout/Nav';
// import { LightCard } from '../Card';
import { Moon, Sun } from 'react-feather';
import { RowFixed } from '../Row';
import Web3Status from '../Web3Status';
import {
  ETH_NAME_AND_SYMBOL,
  // NETWORK_SUPPORTE,
} from '../../constants';

const HeaderFrame = styled.div`
  width: 100vw;
  margin: auto;
  padding: 0.4rem 1.6rem;
  z-index: 2;
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 60px 1fr 120px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 60px 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0.5rem 1rem;
  `}
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`;

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRow = styled(RowFixed)`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  /* background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px; */

  :focus {
    border: 1px solid blue;
  }
`;

const HideSmall = styled.span`
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const HideMedium = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`;

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  gap: 6px;
  margin-right: 12px;
  text-decoration: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`;

const Icon = styled.div`
  transition: transform 0.3s ease;
  margin-right: 5px;
  width: clamp(125px, 10.5vw, 271px);

  :hover {
    transform: scale(1.1);
  }

  img {
    width: 100%;
  }
`;

// const StyledNavExternalLink = styled.a`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 12px;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.text2};
//   font-size: 0.9rem;
//   width: fit-content;
//   padding: 0.3rem 0.6rem;
//   font-weight: 500;
//   transition: 0.3s;

//   &:not(:last-child) {
//     margin-right: 0.16rem;
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//   }

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     border-radius: 8px;
//     padding: 0.3rem 7%;
//     border: 1px solid ${({ theme }) => theme.bg3};

//     &:not(:last-child) {
//       margin-right: 2%;
//     }
//   `};
// `;

// const ModalBackground = styled.div`
//   position: absolute;
//   left: 50%;
//   top: 0;
//   transform: translate(-50%, calc(100% + 5px));
// `;

const ButtonChain = styled.button`
  background-color: ${({ theme }) => theme.bg3};
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;
  padding: 0.3rem;
`;

const TextApp = styled.p`
  color: ${({ theme }) => theme.text1};
  margin: 0;
  font-size: 0.9rem;
  padding: 0.3rem 0.6rem;
  font-weight: 500;
`;

// const Modal = styled.div`
//   background: ${({ theme }) => theme.bg3};
//   border-radius: 0.8rem;
//   padding: 10px 30px;
//   margin: auto;
// `;

// const ListChain = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 10px;
// `;

// const ChainLink = styled.a`
//   color: ${({ theme }) => theme.text1};
//   text-decoration: none;
//   font-size: 20px;
//   font-weight: 500;
//   width: 100%;
//   padding: 5px 15px;
//   border-radius: 0.3rem;
//   text-align: center;
//   white-space: nowrap;

//   :hover {
//     opacity: 20%;
//   }
// `;

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`;

// const LogoText = styled.h2`
//   background: ${({ theme }) => `linear-gradient(180deg, ${theme.logoText} 0%, #000000 100%)`};
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   text-align: center;
//   font-size: 32px;
//   display: block;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     display: none;
//   `};
// `;

const HideBlock = styled.div`
  display: none;
`;

export const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Goerli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.SOPHON_TESTNET]: 'Sophon',
};

export default function Header() {
  const { account, chainId } = useActiveWeb3React();
  // const { t } = useTranslation();
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? ''];
  const [darkMode, toggleDarkMode] = useDarkModeManager();
  // const [showModal, setShowModal] = useState(false);
  // const modalRef = useRef<HTMLDivElement>(null);

  // const openModal = () => {
  //   setShowModal(true);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
  //       closeModal();
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  return (
    <HeaderFrame>
      <HeaderRow>
        <Title href=".">
          <Icon>
            <img src={darkMode ? Logo : Logo} alt="logo" />
          </Icon>
          {/* <LogoText className="coiny-regular">ulturaFi</LogoText> */}
        </Title>
      </HeaderRow>

      <HideMedium>
        <Nav />
      </HideMedium>

      <HeaderControls>
        <HeaderElement>
          <HideBlock>
            <HideSmall>
              <ButtonChain
              // onClick={openModal}
              >
                <TextApp>Sophon</TextApp>
              </ButtonChain>

              {/* {showModal && (
              <ModalBackground>
                <Modal ref={modalRef}>
                  <ListChain>
                    {Object.entries(NETWORK_SUPPORTED).map(([chainId, networkInfo]) => (
                      <ChainLink key={chainId} href={networkInfo.link}>
                        {t(networkInfo.name)}
                      </ChainLink>
                    ))}
                  </ListChain>
                </Modal>
              </ModalBackground>
            )} */}
            </HideSmall>
          </HideBlock>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} {chainId ? ETH_NAME_AND_SYMBOL[chainId].symbol : 'Native Tokens'}
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HideBlock>
          <HeaderElementWrap>
            <StyledMenuButton onClick={toggleDarkMode}>
              {darkMode ? <Moon size={20} /> : <Sun size={20} />}
            </StyledMenuButton>
          </HeaderElementWrap>
        </HideBlock>
      </HeaderControls>
    </HeaderFrame>
  );
}
