import { Web3Provider, ExternalProvider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
// import { PortisConnector } from '@web3-react/portis-connector';
// import { FortmaticConnector } from './Fortmatic';
import { NetworkConnector } from './NetworkConnector';
import { ChainId } from '@uniswap/sdk';
import { RPC_URLS } from '../constants/rpc';

// const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
// const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const DEFAULT_CHAIN_ID: ChainId = parseInt(process.env.REACT_APP_DEFAULT_CHAIN_ID ?? '1');

if (RPC_URLS[DEFAULT_CHAIN_ID] === '') {
  throw new Error(`RPC must be defined for DEFAULT_CHAIN_ID=` + DEFAULT_CHAIN_ID);
}

export const network = new NetworkConnector({
  urls: RPC_URLS,
  defaultChainId: DEFAULT_CHAIN_ID,
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any));
}

export const injected = new InjectedConnector({
  supportedChainIds: [ChainId.SOPHON_TESTNET],
});

// DDD only
export const walletconnect = new WalletConnectConnector({
  rpc: { [ChainId.SOPHON_TESTNET]: RPC_URLS[ChainId.SOPHON_TESTNET] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000,
});

// mainnet only
// export const fortmatic = new FortmaticConnector({
//   apiKey: FORMATIC_KEY ?? '',
//   chainId: 1
// })

// mainnet only
// export const portis = new PortisConnector({
//   dAppId: PORTIS_ID ?? '',
//   networks: [1]
// })

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[DEFAULT_CHAIN_ID],
  appName: 'CulturaFi',
  // appLogoUrl: '',
});

const NETWORK_CONFIG = {
  [ChainId.SOPHON_TESTNET]: {
    name: 'Sophon Testnet',
    scanURL: 'https://explorer.testnet.sophon.xyz',
  },
};

export const setupNetwork = async (externalProvider?: ExternalProvider | any) => {
  const provider = externalProvider || window.ethereum;

  const chainId = DEFAULT_CHAIN_ID as keyof typeof NETWORK_CONFIG;
  if (!NETWORK_CONFIG[chainId]) {
    console.error('Invalid chain id');
    return false;
  }
  if (provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
      return true;
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: NETWORK_CONFIG[chainId].name,
                nativeCurrency: {
                  name: 'SOPH',
                  symbol: 'SOPH',
                  decimals: 18,
                },
                rpcUrls: RPC_URLS[DEFAULT_CHAIN_ID],
                blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      }
      return false;
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined");
    return false;
  }
};
