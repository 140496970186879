import { ChainId, JSBI, Percent, Token, WETH } from '@uniswap/sdk';
import { AbstractConnector } from '@web3-react/abstract-connector';
import {
  // fortmatic,
  injected,
  // portis,
  walletconnect,
  walletlink,
} from '../connectors';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const FACTORY_ADDRESS = '0x45c1f8AF23Ea55e51927EE9A4d456fa7BCc5F0Fe';
export const ROUTER_ADDRESS = '0x38AD9cd354487ff2788eC797795e746B37d3B850';
export const LEADER_BOARD_ADDRESS = '0x78FEB1AbF8AAC4b8204ED1c1E8e2845823F0102d';

export const LP_TOKEN_NAME = 'Sophon LPs';
export const LP_TOKEN_SYMBOL = 'SOP-LP';

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

// ETH tokens
export const DAI = new Token(
  ChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
);
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C');
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD');
export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound');
export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker');
export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth');
export const WBTC = new Token(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 8, 'WBTC', 'Wrapped BTC');

// custom tokens
export const SOPHON_MTS = new Token(
  ChainId.SOPHON_TESTNET,
  '0x9B7EA63EF5062661570afe548e3AD691D5652527',
  18,
  'WSOPH',
  'SophonSwap'
);

// export const BITGERT_3DC = new Token(ChainId.BITGERT, '0x5feDA75eaB27814Cba0694C9711F7d4abEa5b0b5', 8, '$3DC', '3D City');
// export const DARRIVAL = new Token(ChainId.BITGERT, '0xeB18A16A08530b811523fA49310319809ac4c979', 9, 'DRV', 'Darrival');
// export const BITGERT_ETHERI = new Token(ChainId.BITGERT, '0xeA61Dc96F105469522d39cBF7bD59b42393678F7', 18, 'ETHi', 'Ether IcecreamSwap');
// export const BITGERT_DOGECOINI = new Token(ChainId.BITGERT, '0x46a8E16dB8Bb241618873bCA21Ef02F120EA4125', 18, 'DOGEi', 'Dogecoin IcecreamSwap');
// export const BITGERT_BNBI = new Token(ChainId.BITGERT, '0x74446a7418BFbFCDe3F1f6bCaFFA097d050a6dD8', 18, 'BNBi', 'BNB IcecreamSwap');
// export const BITGERT_SHIBAI = new Token(ChainId.BITGERT, '0xADF3051f6fbC1f42ee20B2eDb47EA7f6CcaBe978', 18, 'SHIBi', 'Shiba Inu IcecreamSwap');
// export const BITGERT_DAII = new Token(ChainId.BITGERT, '0x71Ef0A490E53Cc177F640169b0347Be4d0f23cc9', 18, 'DAIi', 'DAI IcecreamSwap');
// export const BITGERT_BUSDI = new Token(ChainId.BITGERT, '0xd0CE781960c6356A7175988751bfC8d7cd28EA60', 18, 'BUSDi', 'BUSD IcecreamSwap');

// export const DOGE_ICE = new Token(ChainId.DOGE, '0x81bCEa03678D1CEF4830942227720D542Aa15817', 18, 'ICE', 'IceCream');

// export const DOKEN_ICE = new Token(ChainId.DOKEN, '0x54051D9DbE99687867090d95fe15C3D3E35512Ba', 18, 'ICE', 'IceCream');
// export const DOKEN_USDT = new Token(ChainId.DOKEN, '0x8e6dAa037b7F130020b30562f1E2a5D02233E6c5', 18, 'USDT', 'Tether USD');

// export const FUSE_ICE = new Token(ChainId.FUSE, '0x867f08A3ab824b42e8058a1B48e32E1dF205b092', 18, 'ICE', 'IceCream');
// export const FUSE_DOGE = new Token(ChainId.FUSE, '0x12AA82525DEfF84777fa78578A68ceB854A85f43', 18, 'DOGE', 'DogeCoin');
// export const FUSE_SHIB = new Token(ChainId.FUSE, '0x8687cD1d02A28098571067ddB18F33fEF667C929', 18, 'SHIB', 'Shiba Inu');

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13;
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320;
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS;
export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC';

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [TIMELOCK_ADDRESS]: 'Timelock',
};

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.SOPHON_TESTNET]: [WETH[ChainId.SOPHON_TESTNET]],
  // [ChainId.DOGE]: [WETH[ChainId.DOGE]],
  // [ChainId.DOKEN]: [WETH[ChainId.DOKEN]],
  // [ChainId.FUSE]: [WETH[ChainId.FUSE]],
};

export const ETH_NAME_AND_SYMBOL = {
  [ChainId.MAINNET]: { name: 'Ether', symbol: 'ETH' },
  [ChainId.ROPSTEN]: { name: 'Ether', symbol: 'ETH' },
  [ChainId.RINKEBY]: { name: 'Ether', symbol: 'ETH' },
  [ChainId.GÖRLI]: { name: 'Ether', symbol: 'ETH' },
  [ChainId.KOVAN]: { name: 'Ether', symbol: 'ETH' },
  [ChainId.SOPHON_TESTNET]: { name: 'Sophon', symbol: 'SOPH' },
  // [ChainId.DOGE]: { name: 'Dogecoin', symbol: 'DOGE' },
  // [ChainId.DOKEN]: { name: 'DoKEN', symbol: 'DKN' },
  // [ChainId.FUSE]: { name: 'Fuse', symbol: 'FUSE' },
};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, COMP, MKR, WBTC],
  // [ChainId.DOGE]: [...WETH_ONLY[ChainId.DOGE], DOGE_ICE],
  // [ChainId.SOPHON_TESTNET]: [...WETH_ONLY[ChainId.SOPHON_TESTNET], SOPHON_MTS],
  // [ChainId.DOKEN]: [...WETH_ONLY[ChainId.DOKEN], DOKEN_ICE, DOKEN_USDT],
  // [ChainId.FUSE]: [...WETH_ONLY[ChainId.FUSE], FUSE_ICE],
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]],
  },
};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC],
  // [ChainId.SOPHON_TESTNET]: [...WETH_ONLY[ChainId.SOPHON_TESTNET], SOPHON_MTS],
  // [ChainId.DOGE]: [...WETH_ONLY[ChainId.DOGE], DOGE_ICE],
  // [ChainId.DOKEN]: [...WETH_ONLY[ChainId.DOKEN], DOKEN_ICE],
  // [ChainId.FUSE]: [...WETH_ONLY[ChainId.FUSE], FUSE_ICE]
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC],
  [ChainId.ROPSTEN]: [...WETH_ONLY[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [...WETH_ONLY[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [...WETH_ONLY[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [...WETH_ONLY[ChainId.KOVAN]],
  [ChainId.SOPHON_TESTNET]: [...WETH_ONLY[ChainId.SOPHON_TESTNET], SOPHON_MTS],
  // [ChainId.DOGE]: [...WETH_ONLY[ChainId.DOGE], DOGE_ICE],
  // [ChainId.DOKEN]: [...WETH_ONLY[ChainId.DOKEN], DOKEN_ICE, DOKEN_USDT],
  // [ChainId.FUSE]: [...WETH_ONLY[ChainId.FUSE], FUSE_ICE, FUSE_DOGE, FUSE_SHIB],
};

enum ChainIdSupported {
  SOPHON_TESTNET = 531050104,
}

export const NETWORK_SUPPORTED: { [chainId in ChainIdSupported]: { [name: string]: string } } = {
  [ChainIdSupported.SOPHON_TESTNET]: { name: 'Sophon', link: '' },
};

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin'),
    ],
    [USDC, USDT],
    [DAI, USDT],
  ],
  // [ChainId.SOPHON_TESTNET]: [[WETH[ChainId.SOPHON_TESTNET], SOPHON_MTS]],
  // [ChainId.DOGE]: [
  //   [WETH[ChainId.DOGE], DOGE_ICE],
  // ],
  // [ChainId.DOKEN]: [
  //   [WETH[ChainId.DOKEN], DOKEN_ICE],
  // ],
  // [ChainId.FUSE]: [
  //   [WETH[ChainId.FUSE], FUSE_ICE],
  // ]
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
  },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true,
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true,
  // },
};

export const NetworkContextName = 'NETWORK';
// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;
// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7);
export const BIG_INT_ZERO = JSBI.BigInt(0);
// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%
// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000));
export const ZERO_PERCENT = new Percent('0');
export const ONE_HUNDRED_PERCENT = new Percent('1');
